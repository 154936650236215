import React from "react"
import styled from "styled-components"
import _ from "lodash"
import { Col, Collapse, Row } from "antd"

import Layout from "../components/layout"
import { Color } from "../config"

import Background from "../components/shared/Background"
import { useColorsContext, useColorsDataLoading } from "../data/colorsContext"

const { Panel } = Collapse

const DirectorsPage = () => {
  const { ctxDirectors, ctxSettings } = useColorsContext()
  useColorsDataLoading()

  return (
    <Layout
      title={"Services"}
      description={"Services"}
      backgroundColor={Color.BLACK}
    >
      <StyledHeading>Services</StyledHeading>
      <Background path={ctxSettings.directorsBackgroundPath} />
      <CustomRow>
        <Col xs={24} sm={24} md={24} lg={12}>
          <StyledCollapse ghost accordion>
            {ctxDirectors.map((x) => {
              return (
                <StyledPanel
                  key={x.Name.replace(" ", "")}
                  showArrow={false}
                  header={x.Name}
                  className="colors-styled-panel"
                >
                  <StyledFunction>{x.Function}</StyledFunction>
                  <StyledText>{x.Text}</StyledText>
                </StyledPanel>
              )
            })}
          </StyledCollapse>
        </Col>
      </CustomRow>
    </Layout>
  )
}

export default DirectorsPage

const CustomRow = styled(Row)`
  margin-top: 75px;
  @media (max-width: 769px) {
    margin-top: 0px !important;
    padding-top: 185px;
  }
`

const StyledCollapse = styled(Collapse)`
  margin: 35px !important;
  color: #fff !important;
`

const StyledPanel = styled(Panel)`
  color: #fff !important;
`

const StyledText = styled.p`
  cursor: pointer;
  padding: 6px 0 !important;
  color: #b9b9b9 !important;
  font-size: 12px;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
`
const StyledFunction = styled.p`
  cursor: pointer;
  padding: 6px 0 !important;
  color: #b9b9b9 !important;
  font-size: 12px;
  text-transform: uppercase;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
`

const StyledHeading = styled.h1`
  color: #000;
  z-index: -999999999;
  position: absolute;
`
